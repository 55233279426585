<template>
  <!-- Header.vue -->
  <a-layout-header
    class="header"
    style="background: #fff; padding: 0 ;
          display: flex;
            "
  >
    <menu-unfold-outlined v-if="collapsed" class="trigger" @click="toggleCollapsed" />
    <menu-fold-outlined v-else class="trigger" @click="toggleCollapsed" />

    <div
      class="right"
      style="display: flex; align-items: center;flex-grow:1;justify-content:flex-end;padding-right:20px"
    >
      <a-dropdown>
        <a class="ant-dropdown-link" @click.prevent>
          Hover me
          <DownOutlined />
        </a>
        <template #overlay>
          <a-menu @click="handleMenuClick">
            <a-menu-item key="1">Clicking me will not close the menu.</a-menu-item>
            <a-menu-item key="2">Clicking me will not close the menu also.</a-menu-item>
            <a-menu-item key="3">Clicking me will close the menu</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </a-layout-header>
</template>
  
<script>
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined
} from "@ant-design/icons-vue";
// import { ref } from "vue";
import { toRefs } from "vue";
export default {
  components: {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DownOutlined
  },
  props: { collapsed: { type: Boolean } },
  setup(props, { emit }) {
    const toggleCollapsed = () => {
      emit("toggle-collapsed");
    };

    const handleMenuClick = ({ key }) => {
      console.log(`Click on item ${key}`);
    };
    return {
      toggleCollapsed,
      ...toRefs(props),
      handleMenuClick
    };
  }
};
</script>

<style scoped>
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}
</style>