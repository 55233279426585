<template>
  <div>
    <a-layout style="min-height: 100vh">
      <Sider :collapsed="collapsed" />
      <a-layout>
        <Header :collapsed="collapsed" @toggle-collapsed="collapsed = !collapsed" />
        <a-layout-content>
          <div class="warp-box">
            <router-view />
          </div>
        </a-layout-content>
        <!-- <a-layout-footer>Footer</a-layout-footer> -->
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import { ref } from "vue";
import Header from "./header";
import Sider from "./sider";
export default {
  name: "AdminLayout",
  components: { Header, Sider },
  setup() {
    return {
      collapsed: ref(false)
    };
  }
};
</script>
<style scoped>
.warp-box {
  margin: 10px;
  background: #fff;
}
</style>