<template>
  <a-layout-sider class="sider" :trigger="null" collapsible>
    <div class="logo" />

    <a-menu v-model="selectedKeys" :defaultSelectedKeys="[$route.path]" @openChange="onOpenChange" @click="menuClick"
      theme="dark" mode="inline">
      <template v-for="(item, index)  in menuList">
        <!-- 如果当前遍历项没有children，视为子菜单项，注意所有的key都是path用于路由跳转，以及当前选中记录 -->
        <a-menu-item :dd="index" v-if="!item.children" :key="item.path">
          <span style="font-size: 15px">{{ item.title }}</span>
        </a-menu-item>

        <!-- 否则视为子菜单，传入菜单信息并且运用下面定义的函数式组件 -->
        <sub-menu v-else :key="item.path" :menu-info="item" />
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import { menuList } from "./json.js";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import SubMenu from "./SubMenu.vue";
export default defineComponent({
  name: "side",
  components: {
    SubMenu
  },

  setup() {
    const router = useRouter();
    const onOpenChange = openKeys => {
      console.log(openKeys);
    };
    const menuClick = ({ item, key, keyPath }) => {
      console.log(item, keyPath);
      router.push({
        path: key
      });
    };
    return {
      menuList,
      selectedKeys: ref(["1"]),
      onOpenChange,
      menuClick
    };
  }
});
</script>
<style scoped>
.logo {
  height: 32px;
  background: #fff;
  margin: 16px;
}

.sider {
  /* background: #fff; */
}

.site-layout .site-layout-background {
  background: #fff;
}
</style>