export const menuList = [
    {
        key: "order1",
        title: "订单",
        path: "/layout",
        icon: "",
        children: [
            {
                key: "/admin/order",
                title: "普通订单",
                path: "/admin/order",
                icon: "",
            },
        ],
    },

    {
        key: "user",
        title: "系统",
        path: "/admin",
        icon: "",
        children: [
            {
                key: "/admin/user",
                title: "用户",
                path: "/admin/user",
                icon: "",
            },
            {
                key: "/admin/role",
                title: "角色",
                path: "/admin/role",
                icon: "",

            },
        ],
    },



];